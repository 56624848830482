import { useSnackBars, useTextFieldInput } from '@fingo/lib/hooks';
import React, { useMemo } from 'react';
import useCountStep from '@fingo/lib/hooks/useCountStep';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import { useMutation } from '@apollo/client';
import { REGISTER_USER } from '@fingo/lib/graphql';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import useQueryParams from '@fingo/lib/hooks/useQueryParams';
import FirstStepRegister from './steps/FirstStepRegister';
import SecondStepRegister from './steps/SecondStepRegister';
import ThirdStepRegister from './steps/ThirdStepRegister';
import { useLogin } from '../../hooks';

const ThreeStepRegister = () => {
  const { t } = useTranslation();
  const { addAlert } = useSnackBars();
  const [email, setEmail] = useTextFieldInput('');
  const [password, setPassword] = useTextFieldInput('');
  const [firstName, setFirstName] = useTextFieldInput('');
  const [lastName, setLastName] = useTextFieldInput('');
  const [phone, setPhone] = useTextFieldInput('');
  const [rut, setRut] = useTextFieldInput('');
  const [siiPassword, setSiiPassword] = useTextFieldInput('');
  const [step, , nextStep,, resetStep] = useCountStep();
  const cookies = new Cookies();
  const search = useQueryParams();
  const [loginGql] = useLogin(
    email.toLowerCase(),
    password,
  );
  const [registerUser, { loading: loadingRegisterUser }] = useMutation(REGISTER_USER, {
    variables: {
      email: email.toLowerCase(),
      firstName,
      lastName,
      password,
      phoneNumber: `${t('Phone prefix')}${phone}`,
      utmCampaign: (cookies.get('utm_campaign') || search.get('utm_campaign')) || null,
    },
    onCompleted: async () => {
      await loginGql();
      nextStep();
    },
    onError: () => {
      addAlert({ message: 'Hubo un error. Su usuario puede que ya existe. Revise sus datos e intente nuevamente.', id: 'registererrot' });
      resetStep();
    },
  });
  const componentToShow = useMemo(() => {
    if (step === 0) {
      return (
        <FirstStepRegister
          onCompleted={nextStep}
          setEmail={setEmail}
          setPassword={setPassword}
          password={password}
          email={email}
        />
      );
    }
    if (step === 1) {
      return (
        <SecondStepRegister
          onCompleted={registerUser}
          setFirstName={setFirstName}
          firstName={firstName}
          setLastName={setLastName}
          lastName={lastName}
          setPhone={setPhone}
          phone={phone}
          loading={loadingRegisterUser}
        />
      );
    }
    return (
      <ThirdStepRegister
        rut={rut}
        setRut={setRut}
        password={siiPassword}
        setPassword={setSiiPassword}
      />
    );
  }, [
    step,
    password,
    email,
    firstName,
    lastName,
    rut,
    siiPassword,
    loadingRegisterUser,
    phone,
  ]);
  return (
    <Box>
      <Box mr="auto" ml="auto" width="fit-content" pb={2}>
        <MobileStepper
          position="static"
          steps={3}
          activeStep={step}
          variant="dots"
        />
      </Box>
      {componentToShow}
    </Box>
  );
};

export default ThreeStepRegister;
